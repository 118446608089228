import React from "react"
import {Container} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Share from "../../components/Share/Share"
import "./blog.css"

const OnlinePresenceTips = () => {
  const schemaMarkup =
  {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.guidingdigital.com/blog/small-business-online-presence-tips/"
      },
      "headline": "5 Tips For Improving Your Online Presence",
      "image": [
          "https://www.guidingdigital.com/images/blog/1x1/online-presence.png",
          "https://www.guidingdigital.com/images/blog/4x3/online-presence.png",
          "https://www.guidingdigital.com/images/blog/16x9/online-presence.png"
          ],  
      "datePublished": "2020-06-18T11:00:00-5:00",
      "dateModified": "2020-06-18T11:00:00-5:00",
      "author": {
          "@type": "Person",
          "name": "Philip Sparks"
      },  
      "publisher": {
          "@type": "Organization",
          "@id": "https://www.guidingdigital.com/",
          "name": "Guiding Digital",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.guidingdigital.com/images/logo.png"
          }
      },
      "description": "COVID-19 has reminded us on how imporant it is to have a solid online presence. In this article I give 5 tips on improving your online presence."
  }

  return (
  <Layout
    title="5 Tips For Improving Your Online Presence | Guiding Digital"
    description="COVID-19 has reminded us on how imporant it is to have a solid online presence. In this article I give 5 tips on improving your online presence." 
    schemaMarkup={schemaMarkup}
    metaUrl="https://www.guidingdigital.com/blog/small-business-online-presence-tips/"
         twitterCardType="summary_large_image"
         twitterImage="https://www.guidingdigital.com/images/blog/2x1/online-presence.png">    
    <HeroBanner
      headline="5 Tips For Improving Your Online Presence"
      className="onlinePresencePage"
    />

    <Container className="blog-post">
      <div className="d-flex mb-1">    
        <div className="p-0 mt-3 align-self-center">
            <span>June 18, 2020</span>
        </div>
        <div className="ml-auto align-self-center">
          <span className="d-none d-sm-inline">Share: </span>
          <Share
            socialConfig={{
                twitterHandle: "GuidingDigital",
                config: {
                    url: `https://www.guidingdigital.com/blog/small-business-online-presence-tips/`,
                    title: "5 Tips For Improving Your Online Presence",
                },
            }}
          />
        </div>
      </div>
        <p>
          Navigating COVID-19 has been challenging for many small businesses.  For small businesses that 
          rely heavily on on-premise customer interaction such as restaurants and retail, you've found 
          yourself having to adjust quickly to maintain your relationships with your customers.  For some 
          that meant setting up a website for the first time.  For others that meant posting to your  
          Facebook page for the first time in 2 years.  Before COVID-19 you may have took your online digital 
          presence for granted.   
          COVID-19 has brought its fair share of challenges and struggles, but one good thing that has come 
          from it has been the innovative solutions and the reminder of how important our online presence is 
          in today's business environment.   As restrictions begin to ease in many states and you begin to be 
          able to catch your breath, I wanted to give you a few tips on establishing or improving your online 
          presence so that you can continue to reach your customers no matter what comes our way in the future.
        </p>
        
        <p>Here are the tips:</p>
        <ol>
          <li><a href="#tip1">Setup or optimize your Google My Business profile.</a></li>
          <li><a href="#tip2">Start small and expand slowly.</a></li>
          <li><a href="#tip3">Focus on accuracy and completeness.</a></li>
          <li><a href="#tip4">Make it easy to find answers to common questions.</a></li>
          <li><a href="#tip5">Engage with your community.</a></li>
        </ol>

        <h2 id="tip1">1. Setup or optimize your Google My Business profile.</h2>
        <p>
          Many of your customer's web searches begin with Google and so keeping this in mind, the first tip is to 
          make sure you have optimized your presence on Google.  One way to do this is through your Google My 
          Business profile.  Google My Business is a free Google service that allows you to manage your online presence on 
          Google's search results and Google Maps.
        </p>
        <p>
          The benefits of having a Google My Business listing include:
        </p>
        <ul>
          <li>Showing up in Google Map searches.</li>
          <li>
            Key information such as hours of operation, location, and product and services are displayed when users 
            search for your business.
          </li>
          <li>
            The ability to collect and respond to reviews from your customers.
          </li>
          <li>Insights from Google on how your customers searched for your business.</li>
          <li>Increased likelihood of showing up in Google's local search.</li>
        </ul>

        <Container style={{maxWidth: "900px"}}>
          <StaticImage
            src="../../images/blog/google-search-results.png"
            alt="Google Search Results"
            layout="fullWidth" />
        </Container>
        
        <p>
          To sign up for a Google My Business profile:
        </p>
        <ol>
          <li>
            Go to <a href="https://www.google.com/business/" target="_blank" rel="noopener noreferrer">Google My Business Login</a> and click the "Manage Now" button.
          </li>
          <li>
            Search for your business name.  If it isn't displayed, click on the "Add your business to 
            Google" link.
          </li>
          <li>
            Continue through the sign up wizard to setup your profile.
          </li>
          <li>
            On the last screen of the wizard you will need to enter your physical address of your business in 
            order to verify your listing.  If you are working out of a home office, don't worry. The address 
            you enter here will not be displayed to the public.  It is used by Google to send you a 
            postcard that contains the information needed to complete your verification.  The verification information 
            will arrive in about a week.  Once you receive the postcard, follow the instructions on the postcard 
            to complete your verification.
          </li>
          <li>
            While you wait on your verification postcard, you can complete your profile.  I would recommend 
            entering the following information at a minimum:
            <ul>
              <li>Business hours</li>
              <li>Products (if applicable)</li>
              <li>Services offered</li>
              <li>Business description</li>
              <li>Photos (if applicable)</li>
              <li>
                  Website URL. Ideally this should be the URL to your business website, but if you don't have a 
                  website yet, you can enter the URL for your Facebook page.</li>
            </ul>
          </li>
        </ol>

        <p>
          Other items to consider including on your Google My Business listing:
        </p>
        <ul>
          <li>
            A COVID-19 update.  You can post changes to your business as a result of COVID-19 
            (curbside service, no in person dining, or special hours).  It also lets you add a call to action 
            button to your profile.  For example, if you are a restaurant and offer online ordering, I would 
            highly recommend you add the "Order online" button.
          </li>
          <li>
            Posts about special offers, announcements, events, or products.  As with the COVID-19 updates, 
            "What's New" posts and product posts also allow you to add a call to action button.
          </li>
        </ul>

        <Container style={{maxWidth: "400px"}}>
          <StaticImage
            src="../../images/blog/google-my-business-covid-update.png"
            alt="Google My Business COVID-19 Update"
            layout="fullWidth" />
        </Container>

        <h2 id="tip2">2. Start small and expand slowly.</h2>
        <p>
          If you are just starting to establish your online presence, don't feel like you must have a 
          presence on every social media platform immediately.  For most business, TikTok can wait!  
          In my opinion, quality and consistency are more important than being everywhere.
        </p>
        <p>
          At a minimum I would recommend that every business have a website and be on at least one social 
          media platform, but even these minimum requirements don't have to happen at the same time.  If 
          it is more important for your business to frequently communicate time sensitive information to 
          your users such as daily specials, then a Facebook page might be a good place for you to start 
          and then as time allows add a website.
        </p>
        <p>
          For whatever digital properties you have, focus on posting consistently and posting with a strategy 
          in mind.  Then when you have mastered that platform you can consider expanding to other platforms 
          that fit your business strategy.  Each platform has its own strengths and so choose the platforms 
          that support the strategy of your business.  Here are brief overviews of the major social media 
          platforms and their strengths:
        </p>
        <ul>
          <li>
            <strong>Facebook</strong> - Facebook is the largest social network platform with over a billion users. 
            Facebook allows you to setup a page for your business that you can then post messages, images, and videos. 
            Customer can then like, comment and share your posts. Posts show up in the newsfeed of those that have 
            liked your page.  You can also create ads to increase your reach outside of your followers.  Because of its 
            large user base, Facebook is a good platform to start with for almost any business.
          </li>
          <li>
            <strong>Twitter</strong> - Twitter is a social platform that allows users to post short messages 
            that are less than 280 characters. Twitter is most popular with millennials and young professionals. 
            Twitter has over 69 million users in the U.S. Consider using Twitter to build brand awareness, provide 
            customer support, or to engage with your followers and potential customers one on one.
          </li>
          <li>
            <strong>Instagram</strong> - Instagram is primarily a mobile platform that allows users to share 
            images and videos. It's primary users are 18-29 years old. Instagram has 1 billion monthly active users. 
            Instagram is a great place to show off your products and business culture.             
          </li>
          <li>
            <strong>LinkedIn</strong> - LinkedIn is a business oriented social networking platform.  Users go to 
            LinkedIn for networking, to search for job opportunities, and to stay up to date in their industry. 
            Consider establishing a presence on LinkedIn to establish credibility and to promote your company's 
            culture.
          </li>
          <li>
            <strong>Pinterest</strong> - Pinterest is a social network that allows users to "pin" images or videos 
            to boards.  Pinterest has 250 million monthly active users. Pinterest can be a great place to showcase 
            your products if you are in the home, parenting, beauty, or food industries.
          </li>
          <li>
            <strong>YouTube</strong> - YouTube is the largest video sharing network and the second largest 
            search engine.  Consider using YouTube to showcase your products, build credibility, or to provide 
            value to potential customers by providing informational how-to type videos.
          </li>
          <li>
            <strong>Snapchat</strong> - Snapchat is a social platform that allows users to post videos or pictures that 
            disappear after being viewed.  Snapchat is most used by 12-24 year olds.  Unless your audience is weighted 
            heavily toward this younger audience, I would recommend claiming your account but don't worry about being 
            in a rush to setup a presence on Snapchat.
          </li>
          <li>
            <strong>TikTok</strong> - TikTok is a mobile app that allows users to create and share short videos. TikTok's 
            primary user base is between 16 and 24 years old.  For small businesses, I would place TikTok in the category 
            of a social media platform that you should monitor but not worry about establishing a presence on yet.
          </li>
        </ul>

        <h2 id="tip3">3. Focus on accuracy and completeness.</h2>
        <h3>Make sure everything is accurate.</h3>
        <p>
          Make sure your website, Google My Business, and social properties are up to date with the 
          latest information.  If customers see one thing on your website and see something different on your 
          Facebook page, they may lose confidence in both sites.  Each digital property doesn't have to 
          contain the same information but for the information that is on both like product offerings, 
          hours of operation and contact information, make sure they are accurate everywhere.
        </p>
        <h3>Make it easy to navigate between your online properties.</h3>
        <p>
          Your website and social channels can contain different information.  
          You may use your website to share your story with your customers and use your Facebook page to 
          share more timely information like daily specials.  To help promote your different platforms make 
          sure you provide links to the other platforms in easy to find places.  On your website, I 
          would recommend linking to your social channels either in your global header or footer.  At a minimum make 
          sure and include your website URL on your social platform.  This will vary from platform to 
          platform but when possible include it in the about section or company description section.
        </p>

        <Container style={{maxWidth: "900px"}}>
          <StaticImage
              src="../../images/blog/social-media-links.png"
              alt="Social Media Links"
              layout="fullWidth" />
        </Container>

        <h3>Consider archiving outdated information.</h3>
        <p>          
          This applies primarily to your website.  If you have 
          older articles or content that no longer provides value, then consider removing them from your 
          site.  For social channels, it is not as crucial to remove older content since users expect to see 
          content in a timeline type of format.
        </p>

        <h2 id="tip4">4. Make it easy to find answers to common questions.</h2>
        <p>
          Put yourself in the shoes of your customers and attempt to provide answers to your customers 
          most common questions in clear and obvious places.  On your website, make sure you have the 
          following information on your home page in an easy to find location:
        </p>
        <ul>
          <li>Hours of operation</li>
          <li>Address (for physical locations)</li>
          <li>Contact Information</li>
          <li>Services you provide (curbside, delivery, dining)</li>
        </ul>

        <p>
          For social channels, make sure you have filled out your bio so that it includes the things mentioned 
          above when possible.
        </p>
        <p>
          Address special circumstances like COVID-19 on your website and social media channels.  
          On your website, place a call out if possible in the header of your page to a dedicated page 
          that addresses the circumstances and how your business is affected.
        </p>
        <p>
          On social channels, keep in mind that it is not enough to just post once about an event.  
          Posts over time will be buried underneath your most recent posts.  For your Facebook page, 
          I would recommend posting at least once per week to address special circumstances like COVID-19 
          if it affects your business's operations.  You can also pin the post to the top of your page so 
          that it remains easy for your customers to locate.
        </p>

        <h2 id="tip5">5. Engage with your community.</h2>
        <p>
          Social Media and your website is great for communicating information to your customers but if 
          you want to build a community of loyal customers using your online presence, you must turn that 
          communication into a two way dialogue.  Here are a few tips on how to engage with your customers 
          and build community:
        </p>
        <ul>
          <li>
            Respond promptly to your customer's questions or concerns.  Don't forget to monitor your 
            Google My Business account, website contact form submissions, social media comments, direct 
            messages on social channels, and even mentions of your brand on twitter and other platforms.
          </li>
          <li>
            Monitor your social channel's comments and engage in the conversation when appropriate.
          </li>
          <li>
            Participate in other Facebook groups.  Participate in the conversations in Facebook groups related 
            to your business and even contribute when appropriate.
          </li>
          <li>
            Ask for user generated content on your social channels.  Challenging your customers to post an 
            image of them using your product on Facebook or Instagram can be a great way to interact with your 
            followers.
          </li>
        </ul>

        <h2>Final thoughts</h2>
        <p>
          I hope this article has given you a few tips that will help you optimize your online presence.  Like a lot of 
          things in life, remember that setting up your online digital presence is a marathon and not a sprint.  If you 
          already have an online presence, optimize what you have before expanding to every social network.  If 
          you are just starting out, its ok to start small.  Start small and learn the skills you need from your website and 
          first social channel before expanding.  Stay focused on consistency and 
          quality over quantity and your customers will notice!
        </p>

        <p>
          If you have any questions about these tips or how Guiding Digital can help you out, feel free to contact us 
          through our social channels or <Link to="/contact/">contact page</Link>.
        </p>
    </Container>
  </Layout>
  )
}

export default OnlinePresenceTips